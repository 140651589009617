import { excludeLocales, getLocalesData } from "@bounce/i18n"

import type { MarketingAppConfig } from "./config.types"
import { featureFlagOverrides } from "../libs/configcat/configCat"

const domain = process.env.NEXT_PUBLIC_BOUNCE_DOMAIN || "usebounce.com"

/**
 * Base configuration for the Bounce application
 *
 * This is build to being as regressive as possible while also providing good
 * defaults. Please override in environment specific files even if it is set
 * correctly here. We want to be as verbose as possible when it comes to
 * configuration.
 */
export const DEFAULT_config: MarketingAppConfig = {
  id: "DEFAULT",
  devMode: true,
  protocol: "https",
  domain: `preview.${domain}`,

  cookieDomain: null,
  prLinks: "https://github.com/Bounceapp/bounce/pull",
  commitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  apis: {
    marketing: `https://preview.${domain}`,
    bounce: "https://graphql.preview.usebounce.io",
    packageAcceptance: "https://package-acceptance-api.preview.usebounce.io",
  },
  apps: {
    customer: `https://customer.preview.${domain}`,
    marketing: `https://preview.${domain}`,
    partner: `https://partner.preview.${domain}`,
    packages: `https://packages.preview.${domain}`,
  },
  intercom: {
    luggageStorageAppId: "uxo0c376",
    packagesAppId: "uxo0c376",
    partnerAppId: "uxo0c376",
  },
  datadog: {
    env: "dev",
    enabled: false,
    defer: true,
    applicationId: "3d62a985-6183-4b70-8618-7a7c9fe7eabf",
    clientToken: "pub8e50b3cc2c1956779a374061145a7883",
    service: "marketing-web",
  },
  segment: {
    writeKey: "TsUpy9PkhpZaOoSSWsLvVGNTNZEyZt0Z",
    debug: false,
    logger: false,
  },
  configCat: {
    key: "1bDZCOyoD0WGGJ9ql6GYzw/dxAt6ibRLkebLvqt9gnPQA",
    flagOverrides: featureFlagOverrides,
  },
  googleMaps: {
    key: "AIzaSyDkPZo6Z00idFYjT06yF8luGKB1jmKy4Nc",
  },
  prismic: {
    repositoryName: "bounce",
  },
  ashby: {
    key: "invalid-key",
  },
  locales: getLocalesData(excludeLocales(["el-GR", "bn-BD", "hi-IN"])),
}
