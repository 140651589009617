export enum CommonFeatureFlag {
  // Don't delete these two flags - they're used for monitoring our setup
  MonitoringDumbFeatureFlag = "monitoring_dumb_feature_flag",
  MonitoringDumbMultiVariantFeatureFlag = "monitoring_dumb_multi_variant_feature_flag",
  // Real flags start here
  CookieConsentBehavior = "feature_consent_banner_behaviour",
  EnablePusher = "feature_pusher",
  EnableSendbirdChat = "feature_sendbird",
  ReservationChatTranslation = "feature_reservation_chat_translation",
  DirectPaymentsEnabled = "feature_direct_store_payments",
}
