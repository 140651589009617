import { useRouter } from "next/router"

import { useAnonymousId } from "@bounce/analytics"
import type { UserMatchers } from "@bounce/configcat"
import { useConfigValue, useFeatureFlags } from "@bounce/configcat"

export enum ConfigValue {
  BackpacksPricingProductMultipliersV2 = "config_backpacks_pricing_product_multipliers_v2",
}

export enum FeatureFlag {
  Backpacks = "feature_backpacks",
  MarketingExitSurvey = "feature_marketing_exit_survey",
  MapOnCityPage = "feature_map_on_city_page",
  NewHomepageHeadline = "feature_new_homepage_headline",
  NewReviewSection = "feature_new_review_section",
}

export const useAllFeatureFlags = () => {
  const { anonymousId } = useAnonymousId()

  const featureFlagsQuery = useFeatureFlags({
    id: anonymousId,
    email: undefined,
    enums: {
      config: ConfigValue,
      flags: FeatureFlag,
    },
  })

  const featureFlags = anonymousId ? featureFlagsQuery.featureFlags : undefined

  return { featureFlags }
}

export const useFeatureFlagUser = (): UserMatchers => {
  const { anonymousId } = useAnonymousId()
  const router = useRouter()

  return {
    id: anonymousId,
    email: undefined,
    custom: {
      platform: "web",
      utmSource: router.query.utm_source?.toString(),
    },
  }
}

export const useFeatureFlag = (flag: FeatureFlag, defaultValue: boolean = false) => {
  const user = useFeatureFlagUser()

  return useConfigValue({ setting: flag, defaultValue, user })
}

export const useMultiVariantFlag = <T extends string>(flag: FeatureFlag | ConfigValue, defaultValue: T) => {
  const user = useFeatureFlagUser()

  return useConfigValue<T>({ setting: flag, defaultValue, user })
}
