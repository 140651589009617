import type { AppProps } from "next/app"
import { useEffect, useState } from "react"

import { Analytics } from "@bounce/analytics"
import { getPlatformProperties } from "@bounce/analytics/src/helpers/platform"

import { useAllFeatureFlags } from "../../../config/flags"

type SegmentProviderProps = {
  locale: string
  route: string
  pageProps: AppProps["pageProps"]
}

const platformAttributes = () => getPlatformProperties("marketing")

/**
 * Segment hooks without child to avoid rerenders
 */
export const SegmentProvider = ({ locale, route, pageProps }: SegmentProviderProps): null => {
  const [isIdentified, setIsIdentified] = useState(false)
  const { featureFlags } = useAllFeatureFlags()

  // Identify the user
  useEffect(() => {
    if (!featureFlags) return

    const identify = async () => {
      await Analytics.identify(undefined, {
        ...platformAttributes(),
        ...featureFlags,
        locale: locale,
      })

      setIsIdentified(true)
    }

    void identify()
  }, [featureFlags, locale])

  // Record the navigation events
  useEffect(() => {
    if (!isIdentified) return
    const pageAnalyticsProps = { ...pageProps?.pageAnalyticsProps, locale }

    // App namespace, route name
    // the url is automatically captured by segment
    Analytics.page("Marketing", route, pageAnalyticsProps)
  }, [route, isIdentified, pageProps?.pageAnalyticsProps, locale])

  return null
}
