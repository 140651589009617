import type { LogsInitConfiguration } from "@datadog/browser-logs"
import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"

import { mergeSessionContext } from "./helpers/mergeSessionContext"
import { sanitizeTiming } from "./helpers/sanitizeTiming"
import type { DatadogClient, SessionContext } from "./types"

type CommonOptions = Pick<LogsInitConfiguration, "trackSessionAcrossSubdomains" | "useSecureSessionCookie">

/**
 * Datadog RUM Browser
 * https://www.npmjs.com/package/@datadog/browser-rum
 */
const Datadog: DatadogClient = {
  init: ({
    enabled = true,
    applicationId,
    clientToken,
    service,
    env,
    version,
    tracingOrigins,
    trackUserInteractions = true,
    trackResources = true,
    trackLongTasks = true,
    sampleRate = 100,
    sessionReplaySampleRate = 20,
  }) => {
    if (!enabled) return

    const commonOptions: CommonOptions = {
      trackSessionAcrossSubdomains: true,
      useSecureSessionCookie: true,
    }

    datadogLogs.init({
      clientToken,
      sessionSampleRate: sampleRate,
      service,
      env,
      version,
      ...commonOptions,
    })

    datadogRum.init({
      applicationId,
      clientToken,
      service,
      env,
      version,
      trackUserInteractions,
      allowedTracingUrls: tracingOrigins?.map(origin => ({ match: origin, propagatorTypes: ["datadog"] })),
      defaultPrivacyLevel: "mask-user-input",
      sessionReplaySampleRate,
      trackResources,
      trackLongTasks,
      sessionSampleRate: sampleRate,
      enableExperimentalFeatures: ["clickmap", "feature_flags"],
      beforeSend: event => {
        if (
          event.type === "error" &&
          event.error.type &&
          ["IgnoredEventCancel", "IgnorableError"].includes(event.error.type)
        ) {
          return false
        }
        return true
      },
      ...commonOptions,
    })
  },
  logger: datadogLogs.logger,
  registerNavigationContainer: () => {},
  getSessionId: () => datadogRum.getInternalContext()?.session_id,
  setUser: async user => datadogRum.setUser(user),
  getSessionContext: datadogRum.getGlobalContext,
  addSessionContext: async (key, value) => {
    const sessionContext: SessionContext = datadogRum.getGlobalContext()

    const newContext = mergeSessionContext({
      context: sessionContext,
      key,
      value,
    })
    return datadogRum.setGlobalContext(newContext)
  },
  addTiming: async name => {
    // Sanitize the name to avoid warnings and keep formatting consistent between web and native
    const sanitizedName = sanitizeTiming(name)
    return datadogRum.addTiming(sanitizedName)
  },
  clearSessionContext: () => datadogRum.setGlobalContext({}),
  addAction: async (name, context) => datadogRum.addAction(name, context),
  addError: async (error, context) => datadogRum.addError(error, context),
  clearUser: async () => datadogRum.clearUser(),
  addFeatureFlagEvaluation: async (key, value) => datadogRum.addFeatureFlagEvaluation(key, value),
}

export default Datadog
